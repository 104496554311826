<template>
  <div>
    <GlobalForm
      class="global-form"
      :init-data="initData"
      :form-item-list="formItemList"
      :inline="true"
      :labelWidth="100"
      round
      :needBtnLoading="true"
      @handleConfirm="handleConfirm"
      confirmBtnName="查询"
    >
      <el-form-item
        class="el_from"
        label="参与商户"
        slot="companyId"
        style="margin: 0"
        label-width="80px"
      >
        <el-select
          v-model="companyId"
          placeholder="请输入或选择商户名称查询"
          filterable
          clearable
          @change="companyIdChange"
        >
          <el-option
            v-for="item in merchSelectList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label-width="80px"
        label="活动名称"
        slot="activityName"
        style="margin:0"
      >
        <el-select
          :disabled="!companyId"
          v-model="activityId"
          :placeholder="companyId?'请输入或选择活动名称查询':'请先选择参与商户'"
          clearable
          filterable
        >
          <el-option
            v-for="item in activityList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        class="el_from"
        label-width="80px"
        label="活动开关"
        slot="isOpend"
        style="margin:0"
      >
        <el-select
          v-model="isOpend"
          placeholder="请选择活动开关查询"
          clearable
        >
          <el-option label="开启" :value="true" ></el-option>
          <el-option label="关闭" :value="false" ></el-option>
        </el-select>
      </el-form-item>
    </GlobalForm>
  </div>
</template>

<script>
import moment from "moment";
import _api from "@/utils/request";
export default {
  data() {
    return {
      companyId: "",
      merchSelectList: [],
      serParanms: {},
      initData: {
        addTime: [
          moment()
            .day(moment().day() - 2)
            .format("YYYY-MM-DD 00:00:00"),
          moment().format("YYYY-MM-DD 23:59:59"),
        ],
      },
      activityId: "",
      isOpend: "",
      activityList: [],
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      formItemList: [
        { slotName: "companyId" },
        { slotName: "activityName" },
        {
          lableWidth: "75px",
          key: "addTime",
          type: "pickerOptions",
          labelName: "添加时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        {
          lableWidth: "75px",
          key: "entryTime",
          type: "pickerOptions",
          labelName: "活动时间",
          valueFormat: "timestamp",
          clearable: true,
          pickerOptions: {
            onPick: ({ maxDate, minDate }) => {
              if (minDate && this.pickerMinDate) {
                this.pickerMinDate = null;
              } else if (minDate) {
                this.pickerMinDate = minDate.getTime();
              }
            },
            disabledDate: (time) => {
              if (this.pickerMinDate) {
                return (
                  time.getTime() > this.pickerMinDate + this.day31 ||
                  time.getTime() < this.pickerMinDate - this.day31
                );
              }
              return false;
            },
          },
        },
        { slotName: "isOpend" },
      ],
    };
  },
  created() {
    this.getKACompanyList()
    this.handleConfirm(this.initData)
  },
  methods: {
    handleConfirm(data, cd) {
      let SeachParams = {
        companyId: this.companyId,
        activityId: this.activityId,
        isOpend: this.isOpend,
        activityStartTime:data&&data.entryTime ? moment(data.entryTime[0]).format("x") : "",
        activityEndTime: data&&data.entryTime ? moment(data.entryTime[1]).format("x") : "",
        createdStartTime:data&&data.addTime ? moment(data.addTime[0]).format("x") : "",
        createdEndTime: data&&data.addTime ? moment(data.addTime[1]).format("x") : "",
        pageNum: 1,
        pageSize: 10,
        disuseLoding: () => {
          if(typeof cd ==='function'){
             cd();
          }
        },
      };
      console.log(SeachParams,'SeachParamsSeachParamsSeachParams');
      
      this.serParanms = SeachParams;
      this.$store.commit("tagsView/SEACH_PARAMS", SeachParams);
    },
    getKACompanyList() {
      _api.getKACompanyList().then((res) => {
        if (res.code === 1) {
          this.merchSelectList = res.data;
        }
      });
    },
    companyIdChange() {
      if(this.companyId){
        _api.getActivitySelectList({companyId: this.companyId}).then((res) => {
          if (res.code === 1) {
            this.activityList = res.data;
          }
        });
      }else{
        this.activityId = ""
        this.activityList = [];
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.tip {
  color: #ff687b;
}
</style>
